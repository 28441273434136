import { setIsReady } from 'balance-bar/components/ZendeskSlice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getZendeskLanguage } from '../../../user/LanguageRegionSettings/languageRegionSettingsUtils';
import getLanguageFromLocalStorage from '../../../utils/getLanguageFromLocalStorage';

const zendeskKey = 'e94fdc5a-162e-421c-96a6-2e50aeedf961';
const zendeskSettings = {
  position: { horizontal: 'left', vertical: 'bottom' },
  color: { theme: '#6400FF' },
  offset: {
    horizontal: '-10px',
    vertical: '4px',
    mobile: {
      horizontal: '-12px',
      vertical: '6px',
    },
  },
};

const Zendesk = () => {
  const dispatch = useDispatch();

  const show = () => {
    const script = document.createElement('script');
    script.async = true;
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
    script.addEventListener('load', () => {
      if (window.zE) {
        const lang = getLanguageFromLocalStorage();
        const language = getZendeskLanguage(lang);
        window.zE('webWidget', 'setLocale', language);
        dispatch(setIsReady(true));
      }
    });
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (!window.zE) {
      show();
      window.zESettings = zendeskSettings;
    }

    return () => {
      if (!window.zE) {
        return;
      }
      delete window.zE;
      delete window.zESettings;
    };
  }, []); // eslint-disable-line

  return null;
};

export default Zendesk;
