import { push } from './dataLayer';

export interface iPageViewProps {
  'event'?: string;
  'event_type'?: string;
  'kyc_full_status'?: string;
  'referral_code'?: string | null;
  'user_country_code'?: string;
  'user_id'?: string;
}

const pageView = (pageViewProps: iPageViewProps) => {
  const { user_id, kyc_full_status, user_country_code, referral_code } = pageViewProps; // eslint-disable-line
  const pageViewPayload = {
    event: 'cake.page_view',
    event_type: 'automatical',
    user_id,
    kyc_full_status, // UNVERIFIED | VERIFIED
    referral_code,
    user_country_code, // IN | SG
  };
  push(pageViewPayload);
};

export default pageView;
