import React, { useCallback } from 'react';
import { Trans } from 'react-i18next';
import { Coin, CoinPrice } from '@cakedefi/cake-sdk';
import BatchRow from './BatchRow';
import Spinner from '../Spinner';

interface IBatchTable {
  canShowMore: boolean,
  onShowMore: () => void,
  type: string,
  isLoggedIn: boolean,
  isLoading: boolean,
  updateAutoCompounding: (arg1: any, arg2: any) => void,
  copyHashProof: (arg: any) => void,
  batches: any[],
  coins: Coin[],
  currentCoinPrices?: CoinPrice[],
  refreshEstimatedAutocompounds?: (arg1: any) => Promise<void>,
  isLoadingPastBatches?: boolean,
  openProofModal?: (arg1: any, arg2: any) => void,
}

const BatchTable = ({
  canShowMore,
  onShowMore,
  type,
  isLoggedIn,
  isLoading,
  updateAutoCompounding,
  copyHashProof,
  batches,
  coins,
  currentCoinPrices = null,
  refreshEstimatedAutocompounds = null,
  isLoadingPastBatches = false,
  openProofModal = null,
}: IBatchTable) => {
  const renderLoadMoreButton = useCallback(() => {
    if (!canShowMore) {
      return null;
    }
    return (
      <>
        {isLoadingPastBatches ? (
          <div className="mt-4">
            <Spinner></Spinner>
          </div>
        ) : (
          <div className="load-more-button">
            <span onClick={onShowMore}>
              <Trans>Load older</Trans>
            </span>
          </div>
        )}
      </>
    );
  }, [canShowMore, isLoadingPastBatches, onShowMore]);

  const renderRows = useCallback(() => batches.map((batch, idx) => {
    const { sprod } = batch;
    const coin = coins.find(c => c.id === sprod.CoinId);
    const currentCoinPrice = currentCoinPrices ? currentCoinPrices.find(coinPrice => coinPrice.CoinId === coin.id) : null;
    const currentCoinPriceUsd = currentCoinPrice ? currentCoinPrice.priceUSD.avg : 0;

    return (
      <BatchRow
        key={`batch-row-${idx}`}
        batch={batch}
        sprod={sprod}
        batchTableType={type}
        isLoggedIn={isLoggedIn}
        isLoading={isLoading}
        updateAutoCompounding={updateAutoCompounding}
        copyHashProof={copyHashProof}
        openProofModal={openProofModal}
        coin={coin}
        currentCoinPriceUsd={currentCoinPriceUsd}
        refreshEstimatedAutocompounds={refreshEstimatedAutocompounds}
      />
    );
  }), [batches, coins, copyHashProof, currentCoinPrices, isLoading, isLoggedIn, openProofModal, refreshEstimatedAutocompounds, type, updateAutoCompounding]);

  return (
    <>
      <div className="grable batch-table card">
        <div className="gr grable-header">
          <div className="gh g-ar g-lg-o1"></div>
          <div className="gh g-ar g-lg-o2 flex-right-desktop"><Trans>Batch</Trans></div>
          <div className="gh g-lg-o3 flex-right-desktop"></div>
          <div className="gh g-lg-o4 flex-right-desktop"><Trans>Start date</Trans></div>
          <div className="gh g-lg-o5 flex-right-desktop"><Trans>End date</Trans></div>
          <div className="gh g-lg-o3 flex-right-desktop"><Trans>Progress</Trans></div>
          <div className="gh g-ar g-lg-o7 flex-right-desktop"><Trans>Starting value</Trans></div>
          <div className="gh g-ar g-lg-o8 flex-right-desktop"><Trans>Target APY</Trans></div>
          <div className="gh g-ar g-lg-o9 flex-right-desktop"><Trans>Actual APY</Trans></div>
          <div className="gh g-lg-o10 flex-right-desktop"></div>
        </div>
        {renderRows()}
      </div>
      {renderLoadMoreButton()}
    </>
  );
};

export default BatchTable;
