import React from 'react';

const Logo = () => (
  <svg width="32px" height="32px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <g className="w" stroke="#FFFFFF" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="square" transform="translate(-72.000000, -1.000000)">
        <path d="M16,26 C31.9984906,26 31.9984906,34 47.9969811,34 C63.9954717,34 63.9954717,26 79.9939623,26 C95.9939623,26 95.9939623,34 111.993962,34 C127.995472,34 127.995472,26 143.996981,26 C159.998491,26 159.998491,34 176,34 C191.998491,34 191.998491,26 207.996981,26"></path>
        <path d="M8,13 C23.9984906,13 23.9984906,21 39.9969811,21 C55.9954717,21 55.9954717,13 71.9939623,13 C87.9939623,13 87.9939623,21 103.993962,21 C119.995472,21 119.995472,13 135.996981,13 C151.998491,13 151.998491,21 168,21 C183.998491,21 183.998491,13 199.996981,13"></path>
        <path d="M0,0 C15.9984906,0 15.9984906,8 31.9969811,8 C47.9954717,8 47.9954717,0 63.9939623,0 C79.9939623,0 79.9939623,8 95.9939623,8 C111.995472,8 111.995472,0 127.996981,0 C143.998491,0 143.998491,8 160,8 C175.998491,8 175.998491,0 191.996981,0"></path>
    </g>
  </svg>
);

export default Logo;
